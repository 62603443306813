import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './logo.css'

const Logo = ({ children, as = 'h1' }) => {
  return <Wrapper as={as}>{children}</Wrapper>
}

Logo.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
}

export default Logo
