import styled from 'styled-components'
import { foregroundColor } from 'constants/theme'
import MEDIA from 'helpers/mediaTemplates'

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 6rem;
  margin-bottom: 4rem;

  ${MEDIA.TABLET`
    padding: 4rem 3.6rem;
  `};

  a {
    color: ${foregroundColor};
    transition: color 0.3s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`
