import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './box.css'

const Box = ({ children }) => <Wrapper>{children}</Wrapper>

Box.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Box
