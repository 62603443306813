import React from 'react'
import { Consumer } from 'store/createContext'
import { Wrapper } from './modetoggle.css'
import DarkIcon from 'images/dark.svg'
import LightIcon from 'images/light.svg'

const ModeToggle = () => (
  <Consumer>
    {theme => (
      <Wrapper onClick={theme.toggleMode}>{theme.darkMode ? <DarkIcon /> : <LightIcon />}</Wrapper>
    )}
  </Consumer>
)

export default ModeToggle
