import styled from 'styled-components'
import { accentColor, foregroundColor } from 'constants/theme'

export const Wrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  svg {
    fill: ${foregroundColor};
    transition: fill 0.3s ease;
    width: 2rem;
    height: 2rem;

    &:hover {
      fill: ${accentColor};
    }
  }
`
