import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import posed from 'react-pose'
import { Wrapper } from './header.css'
import Logo from 'components/logo'
import ModeToggle from 'components/modetoggle'

// Example of a component-specific page transition
const AnimatedWrapper = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
})

const Header = ({ title }) => (
  <AnimatedWrapper>
    <Wrapper>
      <Link to="/">
        <Logo as="h1">{title}</Logo>
      </Link>

      <ModeToggle />
    </Wrapper>
  </AnimatedWrapper>
)

Header.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Header
