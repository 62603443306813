import styled from 'styled-components'
import { foregroundColor } from 'constants/theme'

export const Wrapper = styled.h1`
  display: block;
  font-weight: 700;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 0.02rem;
  color: ${foregroundColor};
`
