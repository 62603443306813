import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Head from 'components/head'
import Header from 'components/header'
import { ThemeProvider } from 'styled-components'
import { Consumer } from 'store/createContext'
import GlobalStyle from 'global.css.js'

const Layout = ({ data, children }) => (
  <Consumer>
    {theme => (
      <React.Fragment>
        <ThemeProvider theme={{ mode: theme.darkMode ? 'dark' : 'light' }}>
          <div>
            <GlobalStyle />
            <Head />
            <Header title={data.site.siteMetadata.siteTitle} />
            {children}
          </div>
        </ThemeProvider>
      </React.Fragment>
    )}
  </Consumer>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
}

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutWithQuery
