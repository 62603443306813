import theme from 'styled-theming'

export const accentColor = theme('mode', {
  light: '#57595c',
  dark: '#b6b6b6',
})

export const subtleAccentColor = theme('mode', {
  light: '#e8e8e8',
  dark: '#262c36',
})

export const backgroundColor = theme('mode', {
  light: '#fff',
  dark: '#14171c',
})

export const foregroundColor = theme('mode', {
  light: '#14171c',
  dark: '#fff',
})
