import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'

export const Wrapper = styled.div`
  padding: 2rem 6rem;
  max-width: 700px;

  ${MEDIA.TABLET`
    padding: 2rem 3.6rem;
  `};
`
